import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import VideosNav from '../../../components/videos-nav'
import LookingForMore from '../../../components/looking-for-more'
import VideoList from '../../../components/video-list'
import ResourcesNav from '../../../components/resources-nav'
import Layout from '../../../components/layout'

export default class Videos extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const metaDescription = `Intro Videos for teaching Math in PreK, Kindergarten, Elementary. Free teaching resources from ${siteTitle}`
    const intro = get(this, 'props.data.intro.edges')
    // weird sorting
    const getCh = ({ node }) => {
      if (/Intro \(full video\)/.test(node.title)) return -1
      return +(node.title.match(/(?:Chapter )(\d+)(?::)/) || [, Infinity])[1]
    }
    intro.sort((a, b) => getCh(a) - getCh(b))
    return (
      <Layout pageTitle="Intro Videos" description={metaDescription}>
        <ResourcesNav path={this.props.path} />
        <VideosNav path={this.props.path} />

        <h1>Intro Videos</h1>
        <VideoList resources={intro} />
        <hr />
        <LookingForMore />
      </Layout>
    )
  }
}

export const Query = graphql`
  query IntroQuery {
    site {
      siteMetadata {
        title
      }
    }
    intro: allContentfulResource(
      filter: { collections: { eq: "intro" } }
      sort: { fields: slug }
    ) {
      edges {
        node {
          slug
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          file {
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`
